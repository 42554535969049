@import '../../styles/variables';

.categoriesContainer {
    display: flex;
    flex-direction: column;

    

    .catMain {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .catGrid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(auto, 1fr);
        }
    }

    @media screen and (max-width: $desktopBreakpoint) {
        .header {
            height: 360px;

            .headerIntro {
                .introHeading {
                    font-size: 2.3rem;
                }
            }
        }
    }

    @media screen and (max-width: $tabletBreakpoint) {
        .header {
            height: 320px;

            .headerIntro {

                .introHeading, .introSubheading{
                    background-color: #ffffff4d;
                }
                .introHeading {
                    font-size: 1.8em;
                }

                .introSubheading {
                    font-size: 0.58em;
                }
            }
        }

        .catMain {
            padding: 1rem;

            .catGrid {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(auto, 1fr);
            }
        }
    }

    @media screen and (max-width: $mobileBreakpoint) {
        .header {
            height: 320px;

            .headerIntro {
                .introHeading {
                    font-size: 1.5em;
                }

                .introSubheading {
                    font-size: 0.5em;
                    width: 85%;
                }
            }
        }
    }
}