@import '../../styles/variables';

.toastContainer {
    background-color: rgba(255, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    font-family: $bodyFont;
    text-align: center;
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 0.4em 1.2em;
    font-weight: 300;
    font-size: 1em;
    color: red;
    filter: drop-shadow(0px 0px 6px rgba(255, 0, 0, 0.2));
    opacity: 0;
    transform: translateY(-20px);
    animation: slideUp 0.3s ease forwards;

    @keyframes slideUp {
        from {
            opacity: 0;
            transform: translateY(-20px);
        }

        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
}