@import '../../styles/variables';

.cartContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 460px;
    height: 480px;
    font-family: $styleFont;
    padding: 1em;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: #ffffff6a;
    color: black;

    .cpBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $mainBg;
        font-family: $styleFont;
        font-weight: 600;
        outline: none;
        border: none;
        width: -moz-fit-content;
        width: fit-content;
        padding: 0.5em;

        &:hover {
            background-color: $accentColor;
            color: $cardWhite;
        }
    }

    .cartProductsBox {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 420px;
        overflow: scroll;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            width: 0;
        }

        .cartProduct {
            display: flex;
            gap: 20px;
            padding: 0.5em;
            background-color: $cardWhite;

            .cpImage {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                }
            }

            .cpDetails {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .cpTitle {
                    font-size: 1.2em;
                    font-weight: 600;
                }

                .cpCountSize {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    margin-bottom: 10px;
                }
            }
        }
    }

    .totalSection {
        background-color: $cardWhite;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .totalSectionBox {
            display: flex;
            justify-content: space-between;
            padding: 1em;

            .totalPrice {
                font-size: 1.5em;
                font-weight: 600;
            }
        }
    }

    @media screen and (max-width: $tabletBreakpoint) {
        width: 360px;
        height: 420px;

        .cpBtn {
            font-size: 0.7em;
        }

        .cartProductsBox {
            height: 380px;

            .cartProduct {
                .cpImage {
                    img {
                        width: 80px;
                        height: 80px;
                    }
                }

                .cpDetails {

                    .cpTitle {
                        font-size: 1em;
                    }

                    .cpCountSize {
                        font-size: 0.7em;
                        gap: 10px;
                        margin-bottom: 5px;
                    }
                }
            }
        }

        .totalSection {
            .totalSectionBox {
                .totalPrice {
                    font-size: 1.2em;
                }
            }
        }
    }

    @media screen and (max-width: $mobileBreakpoint) {
        width: 280px;

        .cpBtn {
            font-size: 0.6em;
        }

        .cartProductsBox {

            .cartProduct {
                .cpImage {
                    img {
                        width: 70px;
                        height: 70px;
                    }
                }

                .cpDetails {
                    gap: 5px;

                    .cpTitle {
                        font-size: 0.8em;
                    }

                    .cpCountSize {
                        font-size: 0.6em;
                    }
                }
            }
        }

        .totalSection {
            .totalSectionBox {
                .totalPrice {
                    font-size: 1em;
                }
            }
        }
    }
}