@import '../../styles/variables';

.welcomeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .wheader,
    .wsubheader {
        text-align: center;
        backdrop-filter: blur(20px);
        background-color: rgba(255, 255, 255, 0.3);
        font-family: $styleFont;
    }

    .wheader {
        font-weight: 600;
        font-size: 2.5em;
        text-align: center;
    }

    .wsubheader {
        font-weight: 400;
        margin-bottom: 20px;
    }

    .redirect {
        .link {
            text-decoration: none;

            img {
                width: 40px;
                height: 40px;
                object-fit: contain;
                padding: 0.2em;
                background-color: $subMainBg;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;

                &:hover {
                    background-color: $accentColor;
                }
            }
        }
    }

    @media screen and (max-width: $desktopBreakpoint) {

        .wheader {
            font-size: 3em;
        }

        .wsubheader {
            font-size: 0.8em;
        }
    }

    @media screen and (max-width: $tabletBreakpoint) {

        .wheader {
            font-size: 2.4em;
            line-height: 35px;
        }

        .wsubheader {
            font-size: 0.5em;
        }

        .redirect {
            .link {
                text-decoration: none;

                img {
                    width: 30px;
                    height: 30px;
                    border-top-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                }
            }
        }
    }

    @media screen and (max-width: $mobileBreakpoint) {

        .wheader {
            font-size: 1.6em;
            line-height: 35px;
        }

        .wsubheader {
            font-size: 0.4em;
        }
    }
}