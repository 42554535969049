@import '../../styles/variables';

.newLaunchContainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .newProduct{
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        background-size: cover;
        position: relative;
        font-family: $styleFont;

        .redirect{
            position: absolute;
            left: 20px;
            top: 20px;
        }

        .tag{
            background-color: $accentColor;
            width: -moz-fit-content;
            width: fit-content;
            position: absolute;
            top: 20px;
            right: 20px;
            padding: 0.2em;
        }

        .ptitle{
            position: absolute;
            left: 20px;
            bottom: 20px;
            padding: 4px;
            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
            background-color: #ffffff4d;
        }
    }

    @media screen and (max-width: $mobileBreakpoint) {
        grid-template-columns: repeat(1, 1fr);

        .newProduct{
            height: 300px;
        }
    }
}