@import '../../styles/variables';

.footer{
    display: flex;
    flex-direction: column;
    gap: $spacingSmall;
    padding: 1.5em 2em;

    .message{
        font-size: 2.5em;
        font-family: $styleFont;
        text-align: center;
    }

    .copyright{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $spacingSmall;
        font-family: $logoFont;
    }

    @media screen and (max-width: $tabletBreakpoint) {
        .message{
            font-size: 1.8em;
            padding: 0 1em;
        }

        .copyright{
            span{
                font-size: 0.6em;
            }
        }
    }

    @media screen and (max-width: $mobileBreakpoint) {
        .message{
            font-size: 1.4em;
            padding: 0 2em;
        }
    }
}