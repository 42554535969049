@import '../../styles/variables';

.homeContainer {
    .section-1 {
        height: 360px;
        background-image: url('../../assets/images/welcomes.jpg');
        background-size: cover;
    }

    .section-2 {
        display: flex;
        flex-direction: column;
        gap: $spacingMedium;
        padding: 1.5em 2em;
    }

    @media screen and (max-width: $tabletBreakpoint) {
        .section-1 {
            height: 300px;
        }

        .section-2 {
            padding: 1.5em 1em;
        }
    }
}