@import '../../styles/variables';

.header {
    height: 360px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .childContainer{
        padding: 3.5em 2em;
    }
}
