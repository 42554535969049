@import './variables';

*{
  transition: 0.3s ease-in-out;
}

html, body {
  margin: 0;
  font-family: $bodyFont;
  font-weight: 500;
  scroll-behavior: smooth;
  background-color: $subMainBg;
}