@import '../../styles/variables';

.bestsellingContainer {

    .bestProduct {
        display: flex;
        justify-content: space-between;

        .content {
            width: 50%;

            .top,
            .middle {
                margin: 20px 20px;
            }

            .top {
                background-color: $accentColor;
                width: fit-content;
                font-size: 1.5rem;
                padding: 10px;
            }

            .middle {
                font-size: 3.5rem;
                width: 70%;
            }
        }

        img {
            width: 50%;
            height: 440px;
            object-fit: cover;
        }

        &:nth-child(odd) {
            flex-direction: row-reverse;
        }

        @media screen and (max-width: $tabletBreakpoint) {
            .content {

                .top,
                .middle {
                    margin: 10px;
                }

                .top {
                    font-size: 1.2rem;
                    padding: 6px;
                }

                .middle {
                    font-size: 2.2rem;
                    width: 80%;
                }
            }

            img {
                height: 320px;
            }
        }

        @media screen and (max-width: $mobileBreakpoint) {
            .content {

                .top {
                    font-size: 0.8rem;
                }

                .middle {
                    font-size: 1.65rem;
                    width: 95%;
                }
            }

            img {
                height: 220px;
            }
        }
    }
}