@import '../../styles/variables';

.navbarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    z-index: +5;

    .nav-right {
        display: flex;
        align-items: center;
        gap: $spacingMedium;

        .cartBox {
            display: none;
            position: absolute;
            top: 100px;
            right: 100px;

            &.active {
                display: flex;
            }
        }

        .profileBox{
            display: none;
            position: absolute;
            top: 100px;
            right: 40px;

            &.active{
                display: flex;
            }
        }
    }

    @media screen and (max-width: $tabletBreakpoint) {
        padding: 1.2rem 1rem;

        .nav-right {
            gap: $spacingSmall;

            .cartBox {
                top: 90px;
                right: 70px;
            }
        }
    }

    @media screen and (max-width: $mobileBreakpoint) {
        .nav-right {
            .cartBox {
                top: 100px;
                right: 20px;
            }
        }
    }
}